import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

// Import images for carousels
import antiSlip1 from './images/antislip1.webp';
import antiSlip2 from './images/antislip2.webp';
import antiSlip3 from './images/antislip3.webp';
import chemicalResistant1 from './images/chemicalresistant1.webp';
import chemicalResistant2 from './images/chemicalresistant2.webp';
import chemicalResistant3 from './images/chemicalresistant3.webp';
import highTraffic1 from './images/hightraffic1.webp';
import highTraffic2 from './images/hightraffic2.webp';
import highTraffic3 from './images/hightraffic3.webp';

// Styled Components for Commercial Page
const CommercialContainer = styled.section`
  padding: 100px 20px 40px 20px;
  background-color: #f9f9f9;
  color: #0f4c81;
  text-align: center;
`;

const CommercialHeading = styled.h2`
  font-size: 2.8rem;
  margin-bottom: 30px;
`;

const CommercialSubheading = styled.p`
  font-size: 1.3rem;
  margin-bottom: 50px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
`;

const SectionHeading = styled.h3`
  font-size: 2rem;
  margin-bottom: 15px;
  color: #0f4c81;
`;

const SectionDescription = styled.p`
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555;
  max-width: 750px;
  margin: 0 auto;
  line-height: 1.6;
`;

const CarouselWrapper = styled.div`
  width: 80vw;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;

  .slick-slide img {
    margin: auto;
    width: 300px; /* Set fixed width */
    height: 300px; /* Set fixed height */
    object-fit: cover; /* Ensure the image covers the entire space without distortion */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .slick-dots {
    margin-top: 40px;
  }

  .slick-dots li button:before {
    color: #0f4c81;
  }

  .slick-prev, .slick-next {
    font-size: 0;
    color: #0f4c81 !important;
    z-index: 1;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 2rem;
    color: #0f4c81;
    content: '‹';
  }

  .slick-next:before {
    content: '›';
  }
`;

const carouselSettings = {
    dots: false, // No dots by default
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false, // No arrows by default
    responsive: [
      {
        breakpoint: 768, // On screens smaller than 768px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true, // Show dots on smaller screens
          arrows: true, // Show arrows on smaller screens
        },
      },
    ],
  };
  
// Commercial Component
const Commercial = () => {
  return (
    <CommercialContainer>
      <CommercialHeading>Commercial Epoxy Solutions</CommercialHeading>
      <CommercialSubheading>
        NEXT LEVEL EPOXY FLOORING provides industry-leading commercial flooring solutions that prioritize safety, durability, and aesthetics. From high-traffic zones to chemical-resistant surfaces, our epoxy solutions will protect and enhance your workspace for years to come.
      </CommercialSubheading>

      {/* Anti-Slip Epoxy Section */}
      <SectionHeading>Anti-Slip Epoxy Flooring</SectionHeading>
      <SectionDescription>
        Ensure the safety of your employees and clients with anti-slip epoxy flooring designed to prevent accidents in high-traffic areas. Ideal for kitchens, gyms, and industrial environments.
      </SectionDescription>
      <CarouselWrapper>
        <Slider {...carouselSettings}>
          <div>
            <img src={antiSlip1} alt="Anti-Slip Epoxy Flooring 1" />
          </div>
          <div>
            <img src={antiSlip2} alt="Anti-Slip Epoxy Flooring 2" />
          </div>
          <div>
            <img src={antiSlip3} alt="Anti-Slip Epoxy Flooring 3" />
          </div>
        </Slider>
      </CarouselWrapper>

      {/* Chemical-Resistant Epoxy Section */}
      <SectionHeading>Chemical-Resistant Epoxy Flooring</SectionHeading>
      <SectionDescription>
        Our chemical-resistant epoxy flooring is perfect for environments where harsh chemicals are used regularly. These floors are easy to clean and can withstand tough conditions in labs, kitchens, and factories.
      </SectionDescription>
      <CarouselWrapper>
        <Slider {...carouselSettings}>
          <div>
            <img src={chemicalResistant1} alt="Chemical-Resistant Epoxy Flooring 1" />
          </div>
          <div>
            <img src={chemicalResistant2} alt="Chemical-Resistant Epoxy Flooring 2" />
          </div>
          <div>
            <img src={chemicalResistant3} alt="Chemical-Resistant Epoxy Flooring 3" />
          </div>
        </Slider>
      </CarouselWrapper>

      {/* High-Traffic Area Epoxy Section */}
      <SectionHeading>High-Traffic Area Epoxy Flooring</SectionHeading>
      <SectionDescription>
        Designed to withstand heavy foot traffic and the daily demands of commercial spaces, our high-traffic epoxy flooring is perfect for retail stores, warehouses, and garages.
      </SectionDescription>
      <CarouselWrapper>
        <Slider {...carouselSettings}>
          <div>
            <img src={highTraffic1} alt="High-Traffic Area Epoxy Flooring 1" />
          </div>
          <div>
            <img src={highTraffic2} alt="High-Traffic Area Epoxy Flooring 2" />
          </div>
          <div>
            <img src={highTraffic3} alt="High-Traffic Area Epoxy Flooring 3" />
          </div>
        </Slider>
      </CarouselWrapper>
    </CommercialContainer>
  );
};

export default Commercial;
