import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Hero from './Hero';
import EpoxyInfo from './EpoxyInfo';
import FlakeCarousel from './FlakeCarousel';
import ContactForm from './ContactForm';
import Gallery from './Gallery';
import Warranty from './Warranty';
import GlobalStyle from './GlobalStyle';
import Footer from './Footer';
import Shop from './Shop'; // Import the Shop component
import Commercial from './Commercial'; // Import the Commercial component
import Classes from './Classes'; // Import the Classes component

function App() {
  return (
    <Router>
      <GlobalStyle /> {/* Add the global styles here */}
      <Header /> {/* Render the Header component */}
      
      {/* Define the routes */}
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero /> {/* Render the Hero component */}
              <EpoxyInfo /> {/* Render the Epoxy Info section */}
              <Warranty /> {/* Render the Warranty section */}
              <FlakeCarousel /> {/* Render the Image Carousel */}
              <Gallery /> {/* Render the Gallery section */}
              <ContactForm /> {/* Render the Contact Us section with the form */}
            </>
          }
        />
        <Route path="/shop" element={<Shop />} /> {/* Shop route */}
        <Route path="/commercial" element={<Commercial />} /> {/* Commercial route */}
        <Route path="/classes" element={<Classes />} /> {/* Classes route */}
      </Routes>
      
      <Footer /> {/* Add Footer at the bottom */}
    </Router>
  );
}

export default App;
