import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/free-solid-svg-icons'; // Import Font Awesome icon
import Header from './Header';
import Footer from './Footer';

// Styled Components
const ShopContainer = styled.div`
  padding: 100px 20px; // Add top padding to accommodate the header
  background-color: #f8f8f8; // Light background for shop section
  text-align: center; // Center align the content
`;

const TemporaryMessage = styled.div`
  margin: 0 auto;
  max-width: 600px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #0f4c81; // Branding blue color
`;

const IconWrapper = styled.div`
  font-size: 4rem;
  margin-bottom: 20px;
  color: #f4a261; // Light orange for icon color
`;

const ShopHeading = styled.h1`
  font-size: 2.5rem;
  color: #0f4c81; // Blue color for branding
  margin-bottom: 20px;
`;

const LaunchDate = styled.p`
  font-size: 1.3rem;
  color: #555;
  margin-bottom: 20px;
`;

const FooterText = styled.p`
  color: #777;
  font-size: 1rem;
`;

const Shop = () => {
  return (
    <>
      <Header /> {/* Render the Header component */}
      <ShopContainer>
        <TemporaryMessage>
          <IconWrapper>
            <FontAwesomeIcon icon={faTools} />
          </IconWrapper>
          <ShopHeading>We're Working on This Section!</ShopHeading>
          <LaunchDate>Come back on <strong>October 15th 2024</strong> for the full launch of our epoxy product offerings.</LaunchDate>
          <FooterText>Stay tuned for our premium epoxy kits and supplies!</FooterText>
        </TemporaryMessage>
      </ShopContainer>
    </>
  );
};

export default Shop;
